<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/lesson/lesson' }">课程列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--内容-->
        <div class="page-content-x">
            <!-- 如果当前课程违规，显示违规内容 -->
            <div v-if="info.examine_status === 3 || info.examine_status === 2"
                style="width: 400px; border-radius: 8px ;position: sticky;top:-20px;background-color: #fff;z-index: 10;padding: 12px 0;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);">
                <div style="border-left: 10px solid #F56C6C;padding-left: 24px;">
                    <h4 style="color: #fc142f;margin: 0;">{{ info.examine_status === 3 ? '当前课程已违规下架' : '当前课程审核不通过' }}</h4>
                    <p style="color: #fc142f;font-size: 14px;margin: 12px 0;">原因：{{ info.examine_reason }}</p>
                    <el-button v-if="is_auth('lesson.lesson.getinfo')" type="warning" size="mini"
                        @click="toEdit(info.lesson_uuid)">{{ info.examine_status === 3 ? '去整改' : '去调整' }}
                    </el-button>
                </div>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>课程详情</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="所属企业">
                            <span class="form-span-text">{{ info.company_name }}</span>
                        </el-form-item>
                        <el-form-item label="课程名称">
                            <span class="form-span-text">{{ info.lesson_name }}</span>
                        </el-form-item>
                        <el-form-item label="课程类型">
                            <el-tag v-if="info.lesson_type === 1" size="mini">团课</el-tag>
                            <el-tag v-if="info.lesson_type === 2" size="mini">训练营</el-tag>
                            <el-tag v-if="info.lesson_type === 3" size="mini">私教</el-tag>
                            <el-tag v-if="info.lesson_type2 === 1" size="mini" type="success">大团课</el-tag>
                            <el-tag v-if="info.lesson_type2 === 2" size="mini" type="success">小团课</el-tag>
                            <el-tag v-if="info.lesson_type2 === 3" size="mini" type="success">小团私</el-tag>
                        </el-form-item>
                        <el-form-item label="运动品类">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in info.lesson_motion_category"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="运动效果">
                            <div class="form-tag_colour-div">
                                <el-tag :key="item.index" v-for="item in info.lesson_target_category"
                                    class="form-tag_colour-tag" effect="dark">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </el-form-item>
                        <el-form-item label="课程价格">
                            <span class="form-span-text">￥{{ info.price_indication }}</span>
                        </el-form-item>
                        <el-form-item label="首次体验价">
                            <span class="form-span-text">{{ info.price_first }}</span>
                        </el-form-item>
                        <el-form-item label="课程时长">
                            <span class="form-span-text">{{ info.lesson_duration }}分钟</span>
                        </el-form-item>
                        <el-form-item label="创建时间">
                            <span class="form-span-text">{{ info.create_time }}</span>
                        </el-form-item>
                        <el-form-item label="更新时间">
                            <span class="form-span-text">{{ info.update_time }}</span>
                        </el-form-item>
                        <el-form-item label="课程状态">
                            <el-tag size="mini" v-if="info.examine_status === 0">审核中</el-tag>
                            <el-tag size="mini" v-if="info.examine_status === 1" type="success">审核通过</el-tag>
                            <el-tag size="mini" v-if="info.examine_status === 2" type="danger">审核失败</el-tag>
                            <el-tag size="mini" v-if="info.examine_status === 3" type="danger">违规下架</el-tag>
                        </el-form-item>
                        <el-form-item label="上架状态">
                            <el-tag size="mini" v-if="info.publish_state === 0" type="warning">下架</el-tag>
                            <el-tag size="mini" v-if="info.publish_state === 1" type="success">上架</el-tag>
                        </el-form-item>
                        <el-form-item label="课程视频">
                            <Qnupload v-model="info.store_video" type="video" :readonly='true' />
                            <span v-if="info.store_video_carousel === 1" class="form-span-text">视频放到轮播图</span>
                        </el-form-item>
                        <el-form-item label="课程封面" prop="cover">
                            <el-image style="width: 120px; height: 90px;" :src="info.cover" fit="contain"
                                :preview-src-list="[info.cover]">
                            </el-image>
                            <br>
                            <span class="form-tip">图片尺寸 宽:高=1:1，用于课程卡片封面展示。</span>
                        </el-form-item>
                        <el-form-item label="课程照片" prop="photo">
                            <el-image v-for="(item, index) in info.lesson_photo" :key="index"
                                style="width: 120px; height: 90px; margin-right: 4px;" :src="item" fit="contain"
                                :preview-src-list="info.lesson_photo">
                            </el-image>
                            <br>
                            <span class="form-tip">图片尺寸 宽:高≈25:28，用于详情页轮播图展示。</span>
                        </el-form-item>
                        <el-form-item label="课程简介">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.introduce"></div>-->
                                <span class="form-span-text">{{ info.introduce }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="训练效果">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.effect"></div>-->
                                <span class="form-span-text">{{ info.effect }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="适用人群">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.suit"></div>-->
                                <span class="form-span-text">{{ info.suit }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="FAQ">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.faq"></div>-->
                                <span class="form-span-text">{{ info.faq }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="注意事项">
                            <el-card class="box-card">
                                <!--<div class="editor-content-see" v-html="info.attention"></div>-->
                                <span class="form-span-text">{{ info.attention }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="详情">
                            <el-card class="box-card">
                                <div class="editor-content-see" v-html="info.details_rich_text"></div>
                            </el-card>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('lesson.lesson.issave') && info.state === 1"
                                @click="() => { this.$emit('toedit') }" size="medium" type="primary">编辑
                            </el-button>
                            <el-button
                                v-if="is_auth('lesson.lesson.upapply') && info.publish_state === 0 && info.state === 1"
                                size="medium" type="success"
                                @click="operation_tip(info.lesson_uuid, info.lesson_name, 'upapply')">申请上架
                            </el-button>
                            <el-button
                                v-if="is_auth('lesson.lesson.withdraw') && info.publish_state !== 0 && info.state === 1"
                                size="medium" type="warning"
                                @click="operation_tip(info.lesson_uuid, info.lesson_name, 'withdraw')">下架撤回
                            </el-button>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>

        </div>

    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'


export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: true,
            lesson_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let lesson_uuid = this.$route.query.lesson_uuid
            if (lesson_uuid !== undefined) {
                this.lesson_uuid = lesson_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({ path: '/lesson/lesson' })    // 返回列表
                    }
                });
            }
        },
        // 门店详情
        getinfo() {
            let postdata = {
                api_name: "lesson.lesson.getinfo",
                token: this.Tool.get_l_cache('token'),
                lesson_uuid: this.lesson_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },

        // 操作提示
        operation_tip(lesson_uuid = '', lesson_name = '', operation = '', publish_state = null) {

            let tip = ''
            let options = {}
            // 上架
            if (operation === 'upapply') {
                tip = '上架【' + lesson_name + '】？'
            }
            // 下架
            if (operation === 'withdraw') {
                tip = '下架【' + lesson_name + '】？'
            }

            // 审批
            if (operation === 'examine') {
                // 通过
                if (publish_state === 1) {
                    tip = '审批通过【' + lesson_name + '】？'
                }
                // 不通过
                if (publish_state === 3) {
                    tip = '审批不通过【' + lesson_name + '】？'
                }
            }


            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(lesson_uuid, operation, publish_state)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(lesson_uuid = '', operation = '', publish_state) {
            let postdata = {
                api_name: "lesson.lesson." + operation,
                token: this.Tool.get_l_cache('token'),
                lesson_uuid: lesson_uuid,
                publish_state,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = true
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/course/course'})
            this.$router.go(-1)
        },
        // 前往编辑
        toEdit(lesson_uuid = "") {
            this.$router.push({
                path: "/lesson/lesson/Edit",
                query: { lesson_uuid: lesson_uuid },
            });
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
